import styled from "@emotion/styled"
import React from "react"
import { fonts, device, colors } from "../layout/GlobalStyles"
import noPoverty from "../../images/programs/no-poverty.svg"
import rapidLogo from "../../images/programs/logo-rapid.svg"
import mlifeLogo from "../../images/logos/logo-regular.svg"
import sdgLogo from "../../images/programs/logo-un.svg"

const BioContainer = styled.section`
  padding-bottom: 72px;

  .line {
    width: 1px;
    height: 80px;
    background: black;
  }
  @media ${device.laptop} {
    padding-bottom: 170px;
    /* padding-bottom: 80px;*/
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const LeftPanel = styled.div`
  padding: 72px 0px;
  h2 {
    font-size: 60px;
    font-weight: 800;
    letter-spacing: -1.88px;
    margin: 0em 0px 10px 00px;
    line-height: 65px;
  }
  p {
    margin: 0 0px 30px 0px;
    color: ${colors.primary2};
    font-size: 40px;
    letter-spacing: -1.25px;
    line-height: 48px;
    font-family: ${fonts.secondary};
  }
  span {
    font-weight: 500;
    position: relative;
    top: 10px;
    font-size: 18px;
    letter-spacing: -0.56px;
    line-height: 27px;
    font-family: ${fonts.primary};
  }

  div {
    margin: 15px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      width: 1px;
      height: 80px;
      position: relative;
      top: 10px;

      background: black;
    }
    .logo {
      width: 140.3px;
    }
  }
  @media ${device.laptop} {
    h2 + p {
      width: 540px;
    }
    div {
      margin: -5px 0px 15px;
    }
    p + div {
      width: 363px;
    }

    min-width: 463px;
    margin-bottom: 130px;
  }
  @media ${device.laptopL} {
    p {
      max-width: 458px;
    }
  }
`

const RightPanel = styled.div`
  div {
    display: flex;
  }
  p {
    font-size: 22px;
  }
  .line {
    height: 80px;
    width: 1px;
    background: ${colors.black};
  }
  .logo-container {
    justify-content: space-between;
  }
  @media ${device.laptop} {
    max-width: 553px;
    position: relative;
    top: 65px;
    p {
      width: 510px;
    }
    div {
      width: 326px;
      margin-top: 0px;
      img:last-of-type {
        position: relative;
        left: 15px;
      }
    }
  }
  p:first-of-type {
    margin-top: 50px;
  }
  p + p {
    margin-top: 40px;
  }
  @media ${device.laptopL} {
    max-width: 553px;
  }
`

export const RapidBio = () => {
  return (
    <BioContainer className="container">
      <LeftPanel>
        <h2>Our Goal</h2>
        <p>Halt extreme poverty.</p>
        <div>
          <img className="logo" src={mlifeLogo} alt="mlife logo" />
          <div></div>
          <img src={sdgLogo} alt="SDG logo" />
        </div>
        <span>MLIFE support(s) the Sustainable Development Goals. </span>
      </LeftPanel>
      <RightPanel>
        <div className="logo-container">
          <img className="program-logo" src={rapidLogo} alt="rapid logo" />
          <div className="line"></div>
          <img src={noPoverty} className="box-image" alt="No Poverty" />
        </div>
        <section>
          <p>
            In 2015, 41 percent of people in Sub-Saharan Africa lived in extreme
            poverty. By 2030, the share could be as large as 87 percent, making
            extreme poverty a Sub-Saharan Africa problem. Of the world's 28
            poorest countries (those with the highest rates of extreme poverty),
            27 are in Sub-Saharan Africa, with rates above 30 percent.
          </p>
          <p>
            MLIFE's RAPID program provides unrestricted small cash grants to
            families in desperate need of basic needs and supports entrepreneurs
            and solutionists to realize goals that matter to them.
          </p>
        </section>
      </RightPanel>
    </BioContainer>
  )
}
