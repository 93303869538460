import styled from "@emotion/styled"
import React from "react"
import { fonts, colors, device } from "./layout/GlobalStyles"

const BlueContainer = styled.div`
  background: ${colors.secondary102};
  color: ${colors.white};
  margin: 0em;
  padding: 25px 25px 45px;
  p:first-of-type {
    padding: 0px;
    font-weight: 300;
    letter-spacing: -1.56px;
    line-height: 47px;
    text-align: center;
    font-size: 32px;
    font-family: ${fonts.secondary};
  }
  p:last-of-type {
    line-height: 47px;
    text-align: center;
    font-size: 22px;
    letter-spacing: -0.94px;
    line-height: 36px;
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
    padding: 138px 0px 103px;
    p:first-of-type {
      margin: 0 auto;
      font-size: 60px;
      max-width: 880px;
      padding: 0px 200px;
      line-height: 65px;
      font-weight: 300;
      letter-spacing: -1.56px;
    }
    p:last-of-type {
      margin-top: 30px;
      font-size: 22px;
    }
  }
`

export const RapidBlueContainer = () => {
  return (
    <BlueContainer>
      <p>"Talent is equally distributed, opportunity is not."</p>
      <p>Leila Janah, Social Entrepreneur</p>
    </BlueContainer>
  )
}
